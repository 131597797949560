import { isLocalhost } from 'Lib/is-localhost/is-localhost';

export const oGrecaptcha = {
    deferredLibLoad: $.Deferred(),
    deferreds: [],
    maxLibLoadDelay: 5000,
    cGrecaptcha: 'js-grecaptcha',
    error: 'Ошибка капчи! Пожалуйста, попробуйте позже.',

    setLib() {
        const script = document.createElement('script');

        script.src = 'https://www.google.com/recaptcha/api.js?onload=grecaptchaOnLoadCallback&render=explicit';
        script.async = true;
        script.defer = true;
        document.getElementsByTagName('body')[0].appendChild(script);

        $(window).on('load', () => {
            const timerId = setTimeout(() => {
                this.deferredLibLoad.reject();
            }, this.maxLibLoadDelay);

            $.when(this.deferredLibLoad).done(() => {
                clearTimeout(timerId);
                console.log(' ♍ GRECAPTCHA lib loaded');
            }).fail(() => {
                console.log(' ♍ GRECAPTCHA lib 404');
            });
        });
    },

    init() {
        this.setLib();
    },

    render($form) {
        $.when(this.deferredLibLoad).done(() => {
            const that = this;

            $form.each(function () {
                const $this = $(this);

                if ($this.find(`.${that.cGrecaptcha}`).length) {
                    const $grecaptchaThis = $this.find(`.${that.cGrecaptcha}`).first();
                    const idGrecaptchaThis = $grecaptchaThis.attr('data-grecaptcha');
                    const idWidgetGrecaptchaThis = $grecaptchaThis.attr('data-grecaptcha-widget-id');

                    window.grecaptcha.reset(idWidgetGrecaptchaThis);
                    $grecaptchaThis.remove();
                    console.log(' ♍ GRECAPTCHA reset() =', idWidgetGrecaptchaThis, idGrecaptchaThis);
                }

                $this.append(`<div class="${that.cGrecaptcha}"></div>`);

                const $grecaptcha = $this.find(`.${that.cGrecaptcha}`).first();

                if ($grecaptcha.length) {
                    const min = 0;
                    const max = 1000;
                    const dataGrecaptcha = Math.floor(Math.random() * (max - min)) + min;
                    const elId = window.grecaptcha.render($grecaptcha[0], {
                        'sitekey': isLocalhost(true) ? '6LchSZoUAAAAAEdROdsEAvsvfW-nLahEs95ayGAE' : '6LeH5pcUAAAAALNt8JHDEbeJpL3QJzMrsKgcoJuN',
                        'callback': () => {
                            that.deferreds[dataGrecaptcha].resolve();
                        },
                        'expired-callback': () => {
                            console.log(' ♍ GRECAPTCHA render() expired-callback()');
                        },
                        'error-callback': () => {
                            console.log(' ♍ GRECAPTCHA render() error-callback()');
                        },
                        'size': 'invisible',
                        'isolated': true,
                    });

                    $grecaptcha.attr('data-grecaptcha', dataGrecaptcha);
                    $grecaptcha.attr('data-grecaptcha-widget-id', elId);
                    that.deferreds[dataGrecaptcha] = $.Deferred();
                    console.log(' ♍ GRECAPTCHA render()');
                }
            });
        });
    },

    check($form) {
        const d = $.Deferred();

        $.when(this.deferredLibLoad).done(() => {
            const $grecaptcha = $form.find(`.${this.cGrecaptcha}`);

            if ($grecaptcha.length) {
                const idGrecaptcha = $grecaptcha.attr('data-grecaptcha');
                const idWidgetGrecaptcha = $grecaptcha.attr('data-grecaptcha-widget-id');

                if (typeof idGrecaptcha === 'string' && typeof idWidgetGrecaptcha === 'string') {
                    if (idGrecaptcha.length && idWidgetGrecaptcha.length) {
                        window.grecaptcha.execute(idWidgetGrecaptcha);

                        if (typeof this.deferreds[idGrecaptcha] !== 'undefined') {
                            $.when(this.deferreds[idGrecaptcha]).done(() => {
                                d.resolve(window.grecaptcha.getResponse(idWidgetGrecaptcha));
                                console.log(' ♍ GRECAPTCHA check success =', idWidgetGrecaptcha, idGrecaptcha);
                            }).fail(() => {
                                d.reject('Вы не верно заполнили капчу');
                                console.log(' ♍ GRECAPTCHA check fail =', idWidgetGrecaptcha, idGrecaptcha);
                            });
                        } else {
                            d.reject(this.error);
                        }
                    } else {
                        d.reject(this.error);
                    }
                } else {
                    d.reject(this.error);
                }
            } else {
                d.reject(this.error);
            }
        }).fail(() => {
            d.resolve('none');
        });

        return d;
    },
};

window.grecaptchaOnLoadCallback = () => {
    oGrecaptcha.deferredLibLoad.resolve();
};

oGrecaptcha.init();