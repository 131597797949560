import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

export class Popup {
    constructor(selector = 'popup', elPopup) {
        this._selector = selector.split('_')[0] && !selector.includes('__') ? selector.split('_')[0] : selector;
        this._elPopup = elPopup || document.querySelector(`.${this._selector}`);
        this._elPopupBox = this._elPopup?.querySelector(`.${this._selector}__box`);
        this._modalBody = this._elPopup?.querySelector(`.${this._selector}__modal-body`);
        this._handleEscClose = this._handleEscClose.bind(this);
        this._handleClickClose = this._handleClickClose.bind(this);
    }

    _handleClickClose(evt) {
        const evtTarget = evt.target;

        if (evtTarget.classList.contains(this._selector) || evtTarget.classList.contains(`${this._selector}__close-box`) || evtTarget.classList.contains(`${this._selector}__close`)) {
            this.close();
        }
    }

    _handleEscClose(evt) {
        if (evt.key === 'Escape') {
            this.close();
        }
    }

    _setEventsListeners() {
        this._elPopup.addEventListener('click', this._handleClickClose);
        document.addEventListener('keydown', this._handleEscClose);
    }

    _removeEventsListeners() {
        this._elPopup.removeEventListener('click', this._handleClickClose);
        document.removeEventListener('keydown', this._handleEscClose);
    }

    _addBtnClose() {
        if (!this._elPopupBox?.querySelector(`.${this._selector}__close-box`)) {
            this._elPopupBox?.insertAdjacentHTML('afterbegin', `<button class="${this._selector}__close-box"><span class="${this._selector}__close"></span></button>`);
        }
    }

    open(scroll = false) {
        if (this._elPopup.classList.contains(`${this._selector}_delete-body-close`) && this._modalBody?.innerHTML === '') {
            this._modalBody.insertAdjacentHTML('beforeend', this._elPopup.querySelector('.popup__modal-hide').innerHTML);
        }

        if (!this._elPopup.classList.contains(`${this._selector}_no-close`)) {
            this._addBtnClose();
            this._setEventsListeners();
        }

        if (!scroll) {
            disableBodyScroll();
        }

        this._elPopup.classList.add(`${this._selector}_open`);
    }

    close() {
        if (this._elPopup.classList.contains(`${this._selector}_delete-body-close`)) {
            this._modalBody.innerHTML = '';
        }

        this._removeEventsListeners();
        clearAllBodyScrollLocks();
        this._elPopup.classList.remove(`${this._selector}_open`);
        this._elPopup.dispatchEvent(new CustomEvent('close.popup.eksmo'));
    }
}