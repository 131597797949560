import { eksmoBP } from 'Lib/bp/bp';
import { speed } from 'Lib/speed/speed';
import { isTouch } from 'Lib/istouch/istouch';
import fastdom from 'fastdom';
import { getW } from 'Lib/w/w';

const $body = $('body');
const sLink = '.link-drop-down';
const cActive = 'link-drop-down_active';
const cNoEdge = 'link-drop-down_no-edge';
const sDrop = '.link-drop-down__drop';
const cClick = 'link-drop-down_click';
const sClose = '.link-drop-down__close';
const cDropFullWidth = 'link-drop-down__drop_full-width';
const cDropWidthCheaderMenu = 'link-drop-down__drop_cheader-menu';
const cMoreContentFewPoints = 'link-drop-down__drop_cheader-menu-few-points';

function setDropPos($els) {
    $els.each(function () {
        const $this = $(this);
        const $drop = $this.find(sDrop).first();

        if ($this.hasClass(cNoEdge) && getW() < eksmoBP.xsMin) {
            if ($drop.length) {
                fastdom.measure(() => {
                    const left = $drop.offset().left - 15;

                    if (left !== 0) {
                        fastdom.mutate(() => {
                            $drop.css({ 'right': left });
                        });
                    }
                });
            }
        } else if ($drop.hasClass(cDropFullWidth) || ($drop.hasClass(cDropWidthCheaderMenu) && getW() < 1380)) {
            // Всплывашка пункта меню "Книги" и "Ещё".
            // Пункт "Ещё" выводится на всю ширину только на <1380, так как у него будет в этом случае большое количество подпунктов
            fastdom.measure(() => {
                const offsetLeft = $this.offset().left;

                fastdom.mutate(() => {
                    if (offsetLeft) {
                        $drop.css({ left: -offsetLeft });
                    } else {
                        $drop.removeAttr('style');
                    }
                });
            });
        } else if ($drop.hasClass(cDropWidthCheaderMenu)) {
            // Позиционирование всплывашки пункта "Ещё", если в нём всё ещё есть подпункты и их более 10 или же
            // если у них тоже в свою очередь есть подпункты
            fastdom.measure(() => {
                $drop.removeAttr('style');

                const coordCheaderContainer = document.querySelector('.cheader__items').getBoundingClientRect().left;
                const coordCheaderMenuContainer = document.querySelector('.link-drop-down__drop_cheader-menu').getBoundingClientRect().left;

                fastdom.mutate(() => {
                    if (coordCheaderContainer) {
                        $drop.css({ left: -(coordCheaderMenuContainer - coordCheaderContainer) });
                    } else {
                        $drop.removeAttr('style');
                    }
                });
            });
            //
        } else {
            $drop.removeAttr('style');
        }

        // Проверка на количество пунктов и подпунктов в "Ещё", логика добавления класса
        // "cmenu__item_more-content_few-points" находится в cmenu.js
        if ($drop.find('.cmenu__item_more-content_few-points').length) {
            $drop.addClass(cMoreContentFewPoints);
        } else {
            $drop.removeClass(cMoreContentFewPoints);
        }
        //
    });
}

function setTrianglePos($selector) {
    $selector.each(function () {
        const $this = $(this);
        const $triangle = $this.find('.link-drop-down__triangle');
        const $popup = $this.find('.link-drop-down__drop');

        if (!$popup.hasClass('link-drop-down__drop_center') && !($popup.closest(`.${cNoEdge}`).length && getW() < eksmoBP.xsMin)) {
            if ($popup.hasClass('link-drop-down__drop_left')) {
                fastdom.measure(() => {
                    let left = parseInt(($this.outerWidth() / 2) - 10);

                    if (left < 3) {
                        left = 3;
                    }

                    fastdom.mutate(() => {
                        $triangle.css({ 'transform': 'none', 'right': 'auto', 'left': left });
                    });
                });
            } else {
                fastdom.measure(() => {
                    let right = parseInt(($this.outerWidth() / 2) - 10);

                    if (right < 3) {
                        right = 3;
                    }

                    fastdom.mutate(() => {
                        $triangle.css({ 'transform': 'none', 'left': 'auto', 'right': right });
                    });
                });
            }
        }
    });
}

function init($selector) {
    let $curSelector = $selector;

    if (typeof $curSelector === 'undefined' || !($curSelector instanceof jQuery)) {
        $curSelector = $(sLink);
    }

    setTrianglePos($curSelector);
}

setTimeout(init, speed);

$body.on('bitrixDynamicCompositeSuccess.eksmo', () => {
    init($(`${sLink}.cauth__cont`));
});

$body.on('mouseenter', sLink, function () {
    const $this = $(this);

    if (!isTouch() && !$this.hasClass(cClick)) {
        $(sLink).removeClass(cActive);
        $this.addClass(cActive);
        setDropPos($this);
        $this.trigger('eksmoLinkDropDown', [true]);
    }
});

$body.on('mouseleave', sLink, function () {
    const $this = $(this);

    if (!isTouch() && !$this.hasClass(cClick)) {
        $this.removeClass(cActive);
        $this.trigger('eksmoLinkDropDown', [false]);
    }
});

$body.on('click', sLink, function (e) {
    const $target = $(e.target);

    if ($target.closest(sDrop).length || $target.is(sDrop).length) {
        if (!$target.closest(sClose).length && !$target.is(sClose).length) {
            return;
        }
    }

    const $this = $(this);

    if (isTouch() || $this.hasClass(cClick)) {
        if (!$this.hasClass(cActive)) {
            $(sLink).removeClass(cActive);
            $this.addClass(cActive);
            setDropPos($this);
            $this.trigger('eksmoLinkDropDown', [true]);
        } else {
            $this.removeClass(cActive);
            $this.trigger('eksmoLinkDropDown', [false]);
        }
    }
});

$(document).click((e) => {
    const $link = $(sLink);

    if ($link.filter(`.${cActive}`).length) {
        const $target = $(e.target);

        if ($target.closest(sLink).length || $target.is(sLink).length) {
            return;
        }

        $link.removeClass(cActive);
        $link.trigger('eksmoLinkDropDown', [false]);
        e.stopPropagation();
    }
});

$body.on('eksmoCallLinkDropDownClose', sLink, function () {
    const $this = $(this);

    $this.removeClass(cActive);
    $this.trigger('eksmoLinkDropDown', [false]);
});

$body.on('eksmoResizeWidth eksmoHeaderFixed', () => {
    setDropPos($(sLink));
});