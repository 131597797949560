import { eventSender } from 'Lib/event-sender/event-sender';
import { oGrecaptcha } from 'Lib/grecaptcha/grecaptcha';
import { showAuth } from 'Lib/show-auth/show-auth';
import { isObject } from 'Lib/is-object/is-object';
import { isNoEmptyString } from 'Lib/is-no-empty-string/is-no-empty-string';
import { isLocalhost } from 'Lib/is-localhost/is-localhost';

const $doc = $(document);
const $body = $('body');
const sForm = '.ajax-form';
const cResponse = 'ajax-form__response';
const sResponse = `.${cResponse}`;
const cFail = 'ajax-form_fail';
const cSuccess = 'ajax-form_success';
const cLoad = 'ajax-form_load';
const sBtn = '.ajax-form__btn';
const cWG = 'ajax-form_wg';
const cOA = 'ajax-form_oa';

function postForm($parent, url, fd) {
    const email = $parent.find('input[type=email]').first().val();
    const $multipleFileUpload = $parent.find('.multiple-file-upload');
    const transactionEmailCode = $parent.attr('data-transaction-email');

    function send() {
        fd.append('http_referer', document.referrer);

        if ($multipleFileUpload.length > 0) {
            const oCustomFiles = $multipleFileUpload.prop('multiple-file-upload') || {};

            if (Array.isArray(oCustomFiles.files) && isNoEmptyString(oCustomFiles.name)) {
                for (let i = 0; i < oCustomFiles.files.length; i++) {
                    fd.append(`${oCustomFiles.name}[]`, oCustomFiles.files[i]);
                }
            }
        }

        if (transactionEmailCode) {
            const data = {
                email,
                code: transactionEmailCode,
            };

            fetch('/ajax/subscribe/transaction/', {
                method: 'POST',
                body: JSON.stringify(data),
            });
        }

        $.ajax({
            url,
            data: fd,
            cache: false,
            contentType: false,
            processData: false,
            type: 'POST',
            success(d) {
                if (d.trim() === 'AUTH') {
                    showAuth();
                } else {
                    const data = $.parseJSON(d);

                    if (typeof data.STATUS === 'boolean' && typeof data.MESSAGE === 'string') {
                        let message = data.MESSAGE;
                        const messageOnlyText = message;

                        $parent.removeClass(cLoad);

                        if (data.STATUS) {
                            const dataLayerEvent = $parent.attr('data-layer-event');

                            $parent.removeClass(cFail);
                            $parent.addClass(cSuccess);
                            message = `<div class="ajax-form__success">${message}</div>`;

                            if (isNoEmptyString(dataLayerEvent)) {
                                eventSender.customDL({ 'event': dataLayerEvent });
                            }

                            $body.trigger('eksmoAjaxFormSuccess', [messageOnlyText, data]);
                            $parent.trigger('eksmoThisAjaxFormSuccess', [messageOnlyText, data]);

                            if ($parent.hasClass('ajax-form_feedback')) {
                                eventSender.eventFormFeedback();
                            }
                        } else {
                            $parent.removeClass(cSuccess);
                            $parent.addClass(cFail);
                            message = `<div class="ajax-form__fail">${message}</div>`;
                            $body.trigger('eksmoAjaxFormFail', [messageOnlyText]);
                            $parent.trigger('eksmoThisAjaxFormFail', [messageOnlyText]);

                            if (isObject(data.ERRORS)) {
                                Object.keys(data.ERRORS).forEach((key) => {
                                    const $curFailInput = $parent.find('input, textarea').filter(`[name=${key}]`);

                                    if ($curFailInput) {
                                        if ($curFailInput.attr('type') === 'file') {
                                            $curFailInput.closest('.fileinput2').find('.fileinput2__file-dropzone').addClass('ajax-form__error');
                                        } else {
                                            $curFailInput.addClass('ajax-form__error');
                                        }

                                        $curFailInput.trigger('eksmoThisInputAjaxFormFail', [data.ERRORS[key]]);
                                    }
                                });
                            }
                        }

                        if ($parent.hasClass('ajax-form_response')) {
                            $parent.html(message);
                        } else {
                            $(sResponse, $parent).html(message);
                        }
                    }
                }
            },
            error() {
                $parent.removeClass(cSuccess);
                $parent.removeClass(cLoad);
                $parent.addClass(cFail);
                $(sResponse, $parent).html('');
                $body.trigger('eksmoAjaxFormFail');
                $parent.trigger('eksmoThisAjaxFormFail');
            },
        });
    }

    if ($parent.hasClass(cWG)) {
        fd.append('wg', 1);
        send();
    } else if ($parent.find('input[type=email]').first().val() !== 'AutotestEksmoRu@eksmo.ru' && !isLocalhost(true)) {
        oGrecaptcha.render($parent);

        oGrecaptcha.check($parent).done((resp) => {
            fd.append('g-recaptcha-response', resp);
            send();
        }).fail((message) => {
            $parent.removeClass(cSuccess);
            $parent.removeClass(cLoad);
            $parent.addClass(cFail);
            $(sResponse, $parent).html(`<div class="ajax-form__fail">${message}</div>`);
        });
    } else {
        send();
    }
}

function onForm(e) {
    const $e = $(e.target);
    const $parent = $e.is(sForm) ? $e : $e.closest(sForm);

    if ($parent instanceof jQuery && $parent.length) {
        const formData = new FormData($parent[0]);
        let action = $parent.find('input[name=action]').attr('value');

        if (!isNoEmptyString(action)) {
            action = $('#action', $parent).val();
        }

        if (!isNoEmptyString(action)) {
            action = $('.ajax-form__action', $parent).val();
        }

        if (!isNoEmptyString(action)) {
            action = $parent.attr('action');
        }

        if (isNoEmptyString(action)) {
            if (!$parent.hasClass(cLoad) && !$parent.hasClass(cSuccess)) {
                const isAO = $parent.hasClass(cOA);
                const isAuth = $('html').hasClass('is-authorized');
                const dataFn = $parent.attr('data-fn');

                if (!isAO || (isAO && isAuth)) {
                    if (action.indexOf('?') > 0) {
                        action = `${action}&rnd=${Math.random()}`;
                    } else {
                        action = `${action}?rnd=${Math.random()}`;
                    }

                    if (!$parent.find(sResponse).length) {
                        $parent.prepend(`<div class="${cResponse}"></div>`);
                    }

                    $parent.removeClass(cFail);
                    $parent.removeClass(cSuccess);
                    $parent.addClass(cLoad);
                    $(sResponse, $parent).html('<div class="loader loader_orange ajax-form__loader"><div class="loader__cont loader__cont_w-full"><svg class="loader__svg"><use xlink:href="#loader"></use></svg></div></div>');

                    if (typeof dataFn === 'string') {
                        $.get(`/ajax/form/?fn=${dataFn}`, (data) => {
                            if (isNoEmptyString(data)) {
                                formData.append('hash', data);
                                postForm($parent, action, formData);
                            }
                        });
                    } else {
                        postForm($parent, action, formData);
                    }
                } else if (isAO && !isAuth) {
                    showAuth();
                }
            }
        }
    }

    return false;
}

$doc.on('submit', sForm, (e) => {
    e.preventDefault();
    onForm(e);
});

$doc.on('click', sBtn, onForm);
$doc.on('eksmoCallAjaxFormSubmit', sForm, onForm);

$doc.on('keypress paste change', `${sForm} input, ${sForm} textarea`, function () {
    const $thisInput = $(this);
    const $parent = $thisInput.closest(sForm);

    $thisInput.trigger('eksmoThisInputAjaxFormChange');

    if ($parent.length) {
        $parent.removeClass(cFail);
        $(sResponse, $parent).html('');
    }
});