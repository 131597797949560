import { isIe } from 'Lib/is-ie/is-ie';
import { onWindowLoaded } from 'Lib/on-window-loaded/on-window-loaded';
import fastdom from 'fastdom';
import { isTouch } from 'Lib/istouch/istouch';
import { getW } from 'Lib/w/w';
import { eksmoBP } from 'Lib/bp/bp';

const $parent = $('.book3d');
const $parentHoverLoad = $('.book3d_hover-load');
const cLoad = 'book3d_load';
const cShow = 'book3d_show';
const cInit = 'book3d_init';

function loadImage(num, elImage) {
    return new Promise((resolve, reject) => {
        const img = new Image();

        img.onload = () => resolve();
        img.onerror = () => reject();
        img.src = elImage.currentSrc?.length ? elImage.currentSrc : elImage.src;
    });
}

async function init($book) {
    if (!isIe() && !isTouch() && getW() > eksmoBP.smMax && $book.length && !($book.hasClass(cInit) || $book.hasClass(cLoad))) {
        const nomcode = $book.attr('data-book3d-nomcode');
        const $plane = $book.find('.book3d__plane');
        const $box = $plane.find('.book3d__box');
        const $originImage = $box.find('.book3d__origin-pic').first();

        $book.addClass(cLoad);

        if ($originImage.length && nomcode?.length) {
            fastdom.mutate(async () => {
                $box.append(`
                    <div class="book3d__side book3d__side_front">
                        <img class="book3d__pic" src="https://cdn.eksmo.ru/v2/${nomcode}/COVER/cover1__w820.jpg" alt="">
                    </div>
                    <div class="book3d__side book3d__side_back">
                        <img class="book3d__pic" src="https://cdn.eksmo.ru/v2/${nomcode}/COVER/cover4__w820.jpg" alt="">
                    </div>
                    <div class="book3d__side book3d__side_left">
                        <img class="book3d__pic" src="https://cdn.eksmo.ru/v2/${nomcode}/COVER/spine__h600.jpg" alt="">
                    </div>
                `);

                const $front = $book.find('.book3d__side_front').first();
                const $back = $book.find('.book3d__side_back').first();
                const $left = $book.find('.book3d__side_left').first();
                const $image = $book.find('.book3d__pic');
                const $frontImage = $front.find($image).first();
                const $backImage = $back.find($image).first();
                const $leftImage = $left.find($image).first();

                if ($frontImage.length && $backImage.length && $leftImage.length) {
                    await Promise.all($image.map(loadImage));

                    onWindowLoaded().then(() => {
                        fastdom.mutate(() => {
                            $book.addClass(cInit);

                            fastdom.measure(() => {
                                const frontWidth = $frontImage.width();
                                const leftWidth = $leftImage.width();
                                const halfLeftWidth = leftWidth / 2;

                                if (frontWidth > leftWidth) {
                                    fastdom.mutate(() => {
                                        $plane.css({ 'transform': `translate3d(0,0,-${halfLeftWidth}px)` });
                                        $front.css({ 'transform': `translate3d(0,0,${halfLeftWidth}px)` });
                                        $back.css({ 'transform': `translate3d(0,0,-${halfLeftWidth}px) rotateY(180deg)` });
                                        $left.css({ 'transform': `translate3d(0,0,-${halfLeftWidth}px) rotateY(-90deg)` });
                                        $book.removeClass(cLoad);
                                        $book.addClass(cShow);
                                        $book.trigger('show.Book3d.eksmo');
                                    });
                                }
                            });
                        });
                    });
                }
            });
        }
    }
}

if (isIe()) {
    $parent.removeClass('book3d_style');
}

init($parent.not($parentHoverLoad));

$parentHoverLoad.on('mouseenter', function () {
    init($(this));
});